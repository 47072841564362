import { forwardRef } from 'react';

import { TamaguiElement, useProps } from '@tamagui/core';

import { FormError, FormErrorProps } from '../error';
import { FormHelperText, FormHelperTextProps } from '../helper';
import { FormLabel, FormLabelProps } from '../label';
import { SelectFrameStyledContext } from './SelectStyledContext';

const ErrorComponent = forwardRef<TamaguiElement, FormErrorProps>((props, ref) => {
  const { namespace } = SelectFrameStyledContext.useStyledContext();

  return <FormError id={`${namespace}_error`} ref={ref} {...props} />;
});

const HelperComponent = forwardRef<TamaguiElement, FormHelperTextProps>((propsIn, ref) => {
  const { namespace } = SelectFrameStyledContext.useStyledContext();

  const props = useProps(propsIn);

  return <FormHelperText id={`${namespace}_helper_text`} ref={ref} {...props} />;
});

const LabelComponent = forwardRef<TamaguiElement, FormLabelProps>((propsIn, ref) => {
  const { namespace } = SelectFrameStyledContext.useStyledContext();

  const props = useProps(propsIn);

  return <FormLabel id={`${namespace}_label`} ref={ref} {...props} />;
});

export const SelectFrameStatics = {
  Error: FormError.styleable(ErrorComponent),
  Helper: FormHelperText.styleable(HelperComponent),
  Label: FormLabel.styleable(LabelComponent),
};
