import { ForwardedRef, ReactElement, Ref, forwardRef } from 'react';

import { GetProps, TamaguiElement, useProps } from '@tamagui/core';

import { InputFrame } from '../InputFrame';
import { SelectFrameStyledContext } from './SelectStyledContext';

interface SelectInputFrameExtraProps<T> {
  activeValue?: T | null;
}

const SelectInputFrameComponent = forwardRef(
  <T,>(propsIn: GetProps<typeof InputFrame> & SelectInputFrameExtraProps<T>, ref: ForwardedRef<TamaguiElement>) => {
    const { activeValue, hoverStyle, pressStyle, ...rest } = useProps(propsIn);

    const { isDisabled, isError, isFocused, isInvalid, isOpen, namespace } =
      SelectFrameStyledContext.useStyledContext();

    return (
      <InputFrame
        ref={ref}
        w="100%"
        role="combobox"
        aria-autocomplete="none"
        aria-disabled={isDisabled}
        aria-haspopup="listbox"
        aria-controls={`${namespace}_dropdown`}
        aria-labelledby={`${namespace}_label`}
        aria-describedby={isError ? `${namespace}_error` : `${namespace}_helper_text`}
        aria-expanded={isOpen}
        aria-activedescendant={activeValue != null ? `${namespace}_element_${activeValue}` : undefined}
        aria-invalid={isInvalid || isError ? 'true' : 'false'}
        backgroundColor="$onSurface.neutral.zebra"
        hoverStyle={{
          borderColor: isFocused || isOpen ? 'transparent' : '$onSurface.neutral.default',
          cursor: 'pointer',
          ...hoverStyle,
        }}
        pressStyle={{
          borderColor: isFocused || isOpen ? 'transparent' : '$onSurface.neutral.default',
          cursor: 'pointer',
          ...pressStyle,
        }}
        {...rest}
      />
    );
  },
) as <T>(
  props: GetProps<typeof InputFrame> & SelectInputFrameExtraProps<T> & { ref?: Ref<TamaguiElement> },
) => ReactElement;

export const SelectInputFrame = InputFrame.styleable(SelectInputFrameComponent) as <T>(
  props: GetProps<typeof InputFrame> & SelectInputFrameExtraProps<T> & { ref?: Ref<TamaguiElement> },
) => ReactElement;
export type SelectInputFrameProps = GetProps<typeof SelectInputFrame>;
